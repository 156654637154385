<template>
  <!-- begin:: Header Topbar -->
  <ul class="menu-nav">
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      left
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
          @click="$router.push({ path: '/appointment/list/1' })"
        >
          <span
            class="svg-icon svg-icon-xl svg-icon-primary"
            v-b-tooltip.hover
            title="Obat Kedaluwarsa"
          >
            <inline-svg
              src="/media/svg/icons/Code/Time-schedule.svg"
            />
          </span>
          <span class="pulse-ring"></span>
          <span
            
            class="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1"
          >
            2
          </span>
        </div>
      </template>
    </b-dropdown> -->
  </ul>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import WMPaymentNotification from "@/view/layout/extras/dropdown/PaymentNotification.vue";
import WMPatientNotification from "@/view/layout/extras/dropdown/PatientNotification.vue";
import WMDonorNotification from "@/view/layout/extras/dropdown/DonorNotification.vue";
import WMMedicineNotification from "@/view/layout/extras/dropdown/MedicineNotification.vue";
import WMReportNotification from "@/view/layout/extras/dropdown/ReportNotification.vue";

import module from '@/core/modules/CrudModule.js'
import ApiService from "@/core/services/api.service.js";
// import JwtService from "@/core/services/jwt.service";

export default {
  name: "KTTopbar",
  data() {
    return {
      available_bed: null,
      invoice_today: null,
      new_patients: null,
      new_appointments: null,
      appointment_submission: null,
      doctor_available: null,
      today: new Date().toISOString().substr(0, 10),
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
    };
  },
  components: {
    KTDropdownNotification,
    WMPaymentNotification,
    WMPatientNotification,
    WMDonorNotification,
    WMMedicineNotification,
    WMReportNotification,
  },
  methods: {
    getAvailableBed() {
      ApiService.get("total-available-beds").then((response) => {
        this.available_bed = response.data;
      });
    },
    getAppointmentToday() {
      ApiService.get("total-appointments-today").then((response) => {
        this.new_appointments = response.data;
      });
    },
    getNewPayment() {
      ApiService.get("total-new-payments").then((response) => {
        this.invoice_today = response.data;
        // JwtService.setNewInvoice(response.data)
      });
    },
    updateNewPayment() {
      ApiService.get("total-new-payments").then((response) => {
        this.invoice_today = response.data;
        // JwtService.setNewInvoice(response.data)
      });
    },
    getNewPatient() {
      ApiService.get("total-new-patients").then((response) => {
        this.new_patients = response.data;
      });
    },
    getAppointmentSubmission() {
      ApiService.get("total-submission").then((response) => {
        this.appointment_submission = response.data;
      });
    },

    // Set doctor Today
    getEventColor(event) {
      return event.color;
    },

    random(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    async getAppointment() {
      return await module.list(`schedules/get-calendar?doctor_id=`);
    },

    async getEvents() {
      let arr = [],
        date = this.getMonday(new Date());
      for (let i = 1; i <= 8; i++) {
        i == 1
          ? arr.push(new Date(date.setDate(date.getDate())))
          : arr.push(new Date(date.setDate(date.getDate() + 1)));
      }
      const events = [];
      const schedules = await this.getAppointment();
      let start, end;
      for (let i = 0; i < schedules.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (
            schedules[i].day_name ==
            new Date(arr[j]).toLocaleString("id-ID", { weekday: "long" })
          ) {
            start = new Date(arr[j]);
            start.setHours(schedules[i].start_hour);
            start.setMinutes(schedules[i].start_minute);
            end = new Date(arr[j]);
            end.setHours(schedules[i].end_hour);
            end.setMinutes(schedules[i].end_minute);
            events.push({
              name: schedules[i].name,
              weekdays: 1,
              start: start,
              end: end,
              color: this.colors[this.random(0, this.colors.length - 1)],
              timed: true,
            });
          }
        }
      }
      this.events = events;

      let doctorToday = [];
      let a;
      for (a = 0; a < this.events.length; a++) {
        if (
          this.events[a].start.toISOString().substr(0, 10) ==
          new Date().toISOString().substr(0, 10)
        ) {
          doctorToday.push(this.events[a].name);
        }
        this.doctor_available = doctorToday.length
      }
    },

    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },

    goToAppointment(){
      this.$root.$emit('appointment-submission', 'semoga jalan');
      this.$router.push({ path: '/appointment/list' })
    }
  },
  mounted() {
    this.getAvailableBed();
    this.getAppointmentToday();
    this.getNewPayment();
    this.getNewPatient();
    this.getAppointmentSubmission();
    this.getEvents();

    this.$root.$on("UpdateNewPayment", (text) => {
      console.log(text);
      this.getNewPayment();
    });
    this.$root.$on("UpdateNewAvailableBed", () => {
      this.getAvailableBed();
    });
    this.$root.$on("UpdateNewPatient", () => {
      this.getNewPatient();
    });
    this.$root.$on("UpdateNewAppointment", () => {
      this.getAppointmentToday();
      this.getAppointmentSubmission();
    });
    this.$root.$on("UpdateAvailableDoctor", () => {
      this.getEvents()
    });
  },
  computed: {
    newInvoice() {
      return window.localStorage.getItem("new_invoice");
    },
  },
};
</script>

<template>
  <div
    class="subheader py-2 py-lg-4"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <h5
          class="text-dark font-weight-bold my-2 mr-5"
          style="color: #a12260 !important"
        >
          {{ title }}
        </h5>
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
          <li class="breadcrumb-item">
            <router-link
              to="/"
              class="flaticon2-shelter d-inline text-muted icon-1x"
            ></router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li
              class="breadcrumb-item"
              :key="`${i}-${breadcrumb.id}`"
            >
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="d-inline text-breadcrumb"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span                
                style="color: #a72e68b6 !important;"
                :key="i"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
        <!-- begin::Announcement -->
        <div style="position: absolute; bottom: 0px; width: 100%">
          <marquee class="text-danger marque-loop" :loop="loopTotal" v-if="startContent">
            <span v-html="announcementContent"></span>
          </marquee>
          <!-- <marquee class="text-danger marque-loop" >
            <span>testing</span>
          </marquee> -->
        </div>
        <!--end::Announcement-->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style scoped>
.text-breadcrumb {
  color: #a72e68b6 !important;
}
.text-breadcrumb:hover {
  color: #a72e6846 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data() {
    return {
      announcements: [],
      loopTotal: 2,
      announcementContent: '',
      startContent: false
    };
  },
  methods:{
    async getAnnouncementActive() {
      let response = await module.paginate("announcements/get-shows");

      this.announcements = response.data;
      for(let a = 0; a < this.announcements.length; a++){
        // this.announcementContent += `${this.announcements[a].content_running_text} <img src="/images/hayandra-transparent.png" alt="|" class="max-h-30px" />`
        this.announcementContent += `<i class="fas fa-bullhorn fa-sm text-primary"></i> ${this.announcements[a].content_running_text}  `
        if(a + 1 == this.announcements.length){
          this.startContent = true
          console.log("content", this.announcementContent);

          setTimeout(() => {
            let el = this.$el.getElementsByClassName("marque-loop")[0].loop = 3
          }, 1000);
        }
      }
      console.log("announc", this.announcements);
    },
  },
  mounted(){
    this.getAnnouncementActive();

    this.$root.$on("updateAnnouncements", () => {
      this.getAnnouncementActive();
    });
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
};
</script>
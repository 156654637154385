<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
      style="color: #000000"
    >
      <span class="font-weight-bold font-size-base d-none d-md-inline mr-1">
        Hi,
      </span>
      <span class="font-weight-bolder font-size-base d-none d-md-inline mr-3" style="color: #993366">
        {{ currentUserPersonalInfo.name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <div
          style='height: 20px; width: 20px; position: relative'
          v-if="false"
        >
          <img
            class="image"
            :src="currentUserPersonalInfo.photo"
          >
        </div>
        <span
          v-if="true"
          class="symbol-label font-size-h5 font-weight-bold"
        >
          {{ currentUserPersonalInfo.name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          Profil
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <div style='height: 80px; width: 80px; position: relative'>
              <img
                class="image"
                :src="currentUserPersonalInfo.photo"
              >
            </div>
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUserPersonalInfo.name }}
            </router-link>
            <div class="text-muted mt-1">{{ currentUserPersonalInfo.polyclinic_name }}</div>
            <div class="navi mt-2">
              <a
                href="#"
                class="navi-item"
              >
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <inline-svg src="/media/svg/icons/Communication/Mail-notification.svg" />
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUserPersonalInfo.email }}
                  </span>
                </span>
              </a>
            </div>
            <button
              class="btn btn-light-primary btn-bold"
              @click="onLogout"
            >
              Logout
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            to="/profile"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg src="/media/svg/icons/General/Notification2.svg" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/profile">
                  <div class="font-weight-bold">Profil</div>
                <div class="text-muted">
                  Pengaturan akun
                  <!-- <span class="label label-light-danger label-inline font-weight-bold">
                    update
                  </span> -->
                </div>
                </router-link>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <!-- <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning">
                    <inline-svg src="/media/svg/icons/Shopping/Chart-bar1.svg" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Kotak Pesan</div>
                <div class="text-muted">Email and tugas</div>
              </div>
            </div>
          </router-link> -->
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/appointment/list/0"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/Files/Selected-file.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Aktifitas</div>
                <div class="text-muted">Daftar Perjanjian</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <!-- <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg src="/media/svg/icons/Communication/Mail-opened.svg" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Tugas</div>
                <div class="text-muted">Tugas-tugas dan visit</div>
              </div>
            </div>
          </router-link> -->
          <!--end:Item-->
        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
        <!--begin::Notifications-->
        <div>
          <!--begin:Heading-->
          <h5 class="mb-5">Pengumuman terkini</h5>
          <!--end:Heading-->
          <template v-for="(item, i) in items">
            <!--begin::Item -->
            <div
              class="d-flex align-items-center rounded p-5 gutter-b"
              v-bind:class="`bg-light-success`"
              v-bind:key="i"
              @click="showModal(item.id)"
            >
              <span
                class="svg-icon mr-5"
                v-bind:class="`svg-icon-warning`"
              >
                <span class="svg-icon svg-icon-lg text-danger">
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg :src="item.svg" /> -->
                  <i class="fas fa-bullhorn text-info"></i>
                  <!--end::Svg Icon-->
                </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{ item.title }}
                </a>
                <!-- <span class="text-muted font-size-sm">
                  {{ item.desc }}
                </span> -->
              </div>
              <!-- <span
                class="font-weight-bolder py-1 font-size-lg"
                v-bind:class="`text-${item.type}`"
              >
                {{ item.alt }}
              </span> -->
            </div>
            <!--end::Item -->
          </template>
        </div>
        <!--end::Notifications-->
      </perfect-scrollbar>

      <!--Start::Modal Announcement -->
      <b-modal ref="my-modal" size="lg" hide-footer :title="detailAnnouncement.title">
      <div class="d-block text-center">
        <!-- <h3 class="mb-4">{{detailAnnouncement.title}}</h3> -->
        <img v-if="detailAnnouncement.image" :src="detailAnnouncement.image" width="500" class="mb-10">
        <div class="mt-10" v-html="detailAnnouncement.content"></div>
      </div>
      <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button> -->
    </b-modal>
      <!--End::Modal Announcement -->

      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>

import { LOGOUT } from "@/core/services/store/auth.module"
import { mapGetters } from 'vuex'
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js"
import KTOffcanvas from "@/assets/js/components/offcanvas.js"
import api from "@/core/services/api.service.js"
import module from '@/core/modules/CrudModule.js'

export default {

  name: "KTQuickUser",

  data() {
    return {
      items: [],
      detailAnnouncement: [],
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "/media/svg/icons/Home/Library.svg",
          type: "warning",
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "/media/svg/icons/Communication/Write.svg",
          type: "success",
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "/media/svg/icons/Communication/Group-chat.svg",
          type: "danger",
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "/media/svg/icons/General/Attachment2.svg",
          type: "info",
        },
      ],
    };
  },

  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"])
    // this.getUserAccess()
    this.getAnnouncement()

    this.$root.$on("updateAnnouncements", () => {
      this.getAnnouncement()
    });
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"])
  },

  methods: {
    getUserAccess(){
      let userData = JSON.parse(window.localStorage.getItem("user"))
      api.get('users/' + userData.id).then((response) => {
        console.log("user access", response)
         window.localStorage.setItem("access_right", response.data.meta.access_right)
      })
    },
   async getAnnouncement(){
      let response = await module.paginate('announcements/get-shows')
      console.log("announcements", this.items)
      this.items = response.data
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
   async showModal(id) {
     this.detailAnnouncement = await module.get('announcements/' + id)
        console.log("detail announcements", this.detailAnnouncement)
        this.$refs['my-modal'].show()
    },
    hideModal() {
        this.$refs['my-modal'].hide()
    },
  },

};
</script>

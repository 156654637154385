<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-home mr-5 menu-icon"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/central-dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="eksekutif_dashboard"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-home mr-5 menu-icon"></i>
          <span class="menu-text">Eksekutif Dashboard</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/polyclinic"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="polyclinic"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-heartbeat mr-5 menu-icon"></i>
          <span class="menu-text">Poliklinik</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/my-appointment"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="my_appointment"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-tasks mr-5 menu-icon"></i>
          <span class="menu-text">Perjanjian Saya</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="titleDoctorPatient">
      <h4 class="menu-text menu-title-color">Dokter dan Pasien</h4>
      <i class="menu-icon flaticon-more-v2 menu-icon"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/doctor') }"
      v-if="doctor"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-user-md mr-5 menu-icon"></i>
        <span class="menu-text">Dokter</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/doctor/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="doctor_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot menu-icon">
                  <span></span>
                </i>
                <span class="menu-text">Daftar Dokter</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/doctor/treatments"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="doctor_treatment"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot menu-icon">
                  <span class="sub-menu-icon"></span>
                </i>
                <span class="menu-text">Riwayat Pengobatan</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren([
          'patient/list',
          'patient/cases',
          'patient/cases-print',
          'patient/documents',
          'prescriptions/list',
        ]),
      }"
      v-if="patient"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-user-injured mr-5 menu-icon"></i>
        <span class="menu-text">Pasien</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/patient/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="patient_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link menu-icon" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Daftar Pasien</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/patient/cases"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="patient_case"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Rekam Medis</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/patient/distribution-map"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="prescription"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Peta Sebaran</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/patient/themplate-print"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="patient_case_print"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cetak Rekam Medis</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/patient/documents"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="patient_document"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Dokumen</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/prescriptions/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="prescription"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Resep Obat</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren([
          'schedule/list',
          'schedule/holidays',
        ]),
      }"
      v-if="schedule"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-calendar-alt menu-icon mr-5"></i>
        <span class="menu-text">Jadwal</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/schedule/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="schedule_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Semua Jadwal</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/schedule/holidays"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="schedule_holiday"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Hari Libur</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/appointment') }"
      v-if="appointment"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-clipboard-list menu-icon mr-5"></i>
        <span class="menu-text">Perjanjian</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/appointment/list/0"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="appointment_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Lihat Semua</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/appointment/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="appointment_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Buat Janji</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/appointment/todays"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="appointment_today"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Hari Ini</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/appointment/upcoming"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="appointment_upcoming"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Akan Datang</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/appointment/calendar"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="appointment_calendar"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Kalender</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li class="menu-section" v-if="titlePaymentHumanResource">
      <h4 class="menu-text menu-title-color">Keuangan & SDM</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren([
          'payment/list',
          'payment/add',
          'payment/edit',
          'payment/detail',
          'payment-category/list',
          'payment-category/add',
          'payment-category/edit',
          'payment-category/detail',
          'expense-categories/list',
          'expense-categories/add',
          'expense-categories/edit',
          'expense-categories/detail',
          'expense/list',
          'expense/add',
          'expense/edit',
          'expense/detail',
          'journal-reports',
          'coas/list',
          'coas/add',
          'coas/detail',
          'coas/edit',
          '/purchase-orders/list',
          '/purchase-orders/add',
          '/purchase-orders/edit',
          '/purchase-orders/detail',
        ]),
      }"
      v-if="payment"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-money-bill-wave menu-icon mr-5"></i>
        <span class="menu-text">Keuangan</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/payment/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="payment_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pembayaran</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/payment/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="payment_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tambah Pembayaran</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/payment-category"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="payment_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Biaya Layanan</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/expense/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="expense_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengeluaran</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/expense/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="expense_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tambah Pengeluaran</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/expense-categories/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="expense_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Kategori Pengeluaran</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/purchase-orders/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="payment_po"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Surat Pesanan (PO) Klinik</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/coas/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="coa"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">COA</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-error/error'),
      }"
      v-if="human_resource"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-user-nurse menu-icon mr-5"></i>
        <span class="menu-text">SDM</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/human-resource/employee"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="human_resource_employee"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Karyawan</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/payrolls/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="payroll_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Penggajian</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/remunerations/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="remuneration"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Remunerasi</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/remuneration-types/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="remuneration"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Tipe Remunerasi</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li class="menu-section" v-if="master_payment_method">
      <h4 class="menu-text menu-title-color">Diskon Promo & Kupon</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/masters/coupons/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="master_payment_method"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-percent menu-icon mr-5"></i>
          <span class="menu-text">Kupon </span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/masters/promos/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="master_payment_method"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-tags menu-icon mr-5"></i>
          <span class="menu-text">Diskon & Promo </span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="human_resource_employee">
      <h4 class="menu-text menu-title-color">Customer Relationship (CRM)</h4>
      <i class="menu-icon flaticon-more-v2 menu-icon"></i>
    </li>

    <router-link
      to="/crm/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="human_resource_employee"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-birthday-cake mr-5 menu-icon"></i>
          <span class="menu-text">Pasien Ulang Tahun</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/crm/list-recently"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="human_resource_employee"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-calendar-alt mr-5 menu-icon"></i>
          <span class="menu-text">Pasien Berkunjung Baru-baru ini</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/crm/list-deposit"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="human_resource_employee"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-exclamation mr-5 menu-icon"></i>
          <span class="menu-text">Reminder Deposit Layanan</span>
        </a>
      </li>
    </router-link> -->

    <!-- <router-link
      to="/crm/broadcast-campaigns"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="human_resource_employee"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-calendar-alt mr-5 menu-icon"></i>
          <span class="menu-text">Pesan Siaran (Broadcast Campaign)</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/crm/patient-spending-money"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="human_resource_employee"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-list-ol mr-5 menu-icon"></i>
          <!-- <i class="fas fa-calendar-alt mr-5 menu-icon"></i> -->
          <span class="menu-text">Pasien Teratas</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link
      to="/efectivity-dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-chart-bar mr-5 menu-icon"></i>
          <span class="menu-text">Lap. Efektivitas</span>
        </a>
      </li>
    </router-link> -->

    <li class="menu-section" v-if="titleOperational">
      <h4 class="menu-text menu-title-color">Operasional Pendukung</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren([
          '/lab-report',
          '/lab-procedure',
          '/masters/lab-template',
        ]),
      }"
      v-if="lab"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-flask mr-5 menu-icon"></i>
        <span class="menu-text"> Pemeriksaan Lab </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/lab-procedure/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="lab_procedure"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Biaya Laboratorium </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/lab-report/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="lab_report_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Laporan Lab </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/lab-report/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="lab_report_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Tambah Laporan Lab </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/masters/lab-templates"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="lab_template"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Template </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-pages'),
      }"
      v-if="medical_action"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-diagnoses mr-5 menu-icon"></i>
        <span class="menu-text"> Tindakan Medis </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/medical-actions/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medical_action_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Laporan Tindakan Medis </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/medical-actions/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medical_action_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Tambah Tindakan Medis </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/medicine-packages/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="shortcut_obat_alkes"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Shortcut Obat dan Alkes </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/action-reports/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="recap_action_report_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Rekap Tindakan PerBulan </span>
              </a>
            </li>
          </router-link>

          <!-- <router-link
            to="/masters/action-types/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_action_type"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link"
                @click="navigate"
              >
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Jenis Tindakan</span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-pages'),
      }"
      v-if="bed"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-procedures mr-5 menu-icon"></i>
        <span class="menu-text"> Bangsal </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/beds/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="bed_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Daftar Bangsal </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/beds/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="bed_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Tambah Bangsal </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/beds/category"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="bed_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Kategori </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/beds/allotment"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="bed_allotment"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Pemakaian </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/beds/add-allotment"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="bed_add_allotment"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Tambah Pemakai </span>
              </a>
            </li>
          </router-link>
          <!-- <router-link
            to="/beds/allotment-history"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Riwayat Pemakaian </span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-pages'),
      }"
      v-if="blood_bank"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-tint mr-5 menu-icon"></i>
        <span class="menu-text"> Bank Darah </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/blood-bank/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="blood_bank_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Bank Darah </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/donors/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="donor_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Daftar Pendonor </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/donors/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="donor_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Tambah Donor </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/donor-recipients/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="donor_recipient"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Daftar Penerima Donor </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      to="/announcements/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="announcement"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-bullhorn mr-5 menu-icon"></i>
          <span class="menu-text">Pengumuman</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="titlePharmacy">
      <h4 class="menu-text menu-title-color">Apotek</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/medicine'),
      }"
      v-if="medicine"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-pills menu-icon mr-5"></i>
        <span class="menu-text"> Obat dan Alkes </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/medicine/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medicine_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Daftar Obat dan Alkes</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/medicine/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medicine_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Tambah Obat dan Alkes</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/medicine/category"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medicine_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Kategori </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/medicine/maintain-stock"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medicine_stock"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Stok Obat dan Alkes </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/stock-opnames"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="stock_opname"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Stok Opname </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/medicine/medicine-order"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medicine_stock"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Order Pengambilan Obat </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/medicine/expiration-warning"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medicine_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Peringatan Kadaluarsa </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/medicine/vendors/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medicine_vendor"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Supplier</span>
              </a>
            </li>
          </router-link>
          <!-- <router-link
            to="/medicine/purchase-order"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="medicine_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Purchase Order </span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>

    <!-- <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/purchase-orders'),
      }"
      v-if="medicine"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-mortar-pestle mr-5 menu-icon"></i>
        <span class="menu-text"> PO Apotek </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/purchase/orders"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Daftar PO </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li> -->

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-pages'),
      }"
      v-if="pharmacy"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-mortar-pestle mr-5 menu-icon"></i>
        <span class="menu-text"> Apotek </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/pharmacy/dashboard"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy_dashboard"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Dashboard </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/pharmacy/sales"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy_sales"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Penjualan </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/pharmacy/cashier"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy_cashier"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Tambah Penjualan </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/pharmacy/pharmacy-expenses/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy_expense_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Pengeluaran </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/pharmacy/pharmacy-expenses/add"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy_expense_add"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Tambah Pengeluaran </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/pharmacy/pharmacy-expense-categories/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy_expense_category_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Kategori Pengeluaran </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/purchase-orders-pharmacy"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy_po"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Surat Pesanan (PO) Apotek </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/help"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="pharmacy_report"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Laporan </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/medicine'),
      }"
      v-if="system_online_shop"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-shopping-cart menu-icon mr-5"></i>
        <span class="menu-text"> Toko Online </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/order/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="online_shop"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Daftar Pesanan</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li class="menu-section" v-if="titleReportAnalysis">
      <h4 class="menu-text menu-title-color">Laporan & Analisis</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-error/error'),
      }"
      v-if="report"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-file mr-5 menu-icon"></i>
        <span class="menu-text">Laporan</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/report/payment-report"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="report_payment_report"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pendapatan Klinik</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/report/doctor-commision"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="report_doctor_commision"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Komisi Dokter</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/report/expense-report"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="report_expense_report"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengeluaran Klinik</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/report/pharmacy-report"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="report_pharmacy_report"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pendapatan Apotik</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/report/pharmacy-expense-report"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="report_pharmacy_expense_report"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengeluaran Apotik</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/journal-reports/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="journal_report_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Buku Jurnal</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/report/payroll-report"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="payroll_report_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Penggajian</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/stock-opname-report"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="stock_opname_report_list"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Laporan Stok Opname</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      to="/builder"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="data_analysis"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-chart-bar mr-5 menu-icon"></i>
          <span class="menu-text">Analisis Data</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="titleWebsite">
      <h4 class="menu-text menu-title-color">Website</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/website/setting"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="setting"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-cog mr-5 menu-icon"></i>
          <span class="menu-text">Setelan</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-pages'),
      }"
      v-if="cms"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-tachometer-alt mr-5 menu-icon"></i>
        <span class="menu-text"> CMS </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/website/slide"
            v-slot="{ navigate, isActive, isExactActive }"
            v-if="website_slide"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                href="https://klinik-hayandra.com"
                target="_blank"
                class="menu-link"
                @click="navigate"
              >
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Kunjungi Website</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/website/slide"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="website_slide"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Slide Banner</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/website/service"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="website_service"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Layanan</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/website/posts"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="website_post"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Berita</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/website/post-category"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="website_post_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Kategori Berita</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/website/doctor"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="website_doctor"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Dokter Spesialis</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- <li class="menu-section" v-if="titleEtc"> -->
    <li class="menu-section" v-if="satu_sehat_menu">
      <h4 class="menu-text menu-title-color">Satu Sehat</h4>
      <i class="menu-icon flaticon-more-v2 menu-icon"></i>
    </li>

    <router-link
      to="/satu-sehat/posting"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="satu_sehat_posting"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-cog mr-5 menu-icon"></i>
          <span class="menu-text">Posting</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/satu-sehat/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="satu_sehat_dashboard"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-file-medical mr-5 menu-icon"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/satu-sehat/access-token"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="satu_sehat_api"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-cog mr-5 menu-icon"></i>
          <span class="menu-text">Kode Akses API</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="titleEtc">
      <h4 class="menu-text menu-title-color">Lain-Lain</h4>
      <i class="menu-icon flaticon-more-v2 menu-icon"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/masters'),
      }"
      v-if="master"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-box mr-5 menu-icon"></i>
        <span class="menu-text"> Master Data</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/masters/appointment-statuses/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_appointment_status"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Status Perjanjian</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/diagnoses/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_lab_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Diagnosa</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/classifications/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="classification"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Klasifikasi</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/lab-categories/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_lab_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Kategori Lab</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/buildings/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_building"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Gedung</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/rooms/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_room"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Ruangan</span>
              </a>
            </li>
          </router-link>

          <!-- <router-link
                  to="/masters/medicine-categories/list"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Kategori Obat</span>
                    </a>
                  </li>
                </router-link> -->

          <router-link
            to="/masters/payment-methods/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_payment_method"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Metode Pembayaran</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/warehouses/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_warehouse"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Gudang</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/payment-category-types/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_payment_category_type"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Tipe kategori Pembayaran</span>
              </a>
            </li>
          </router-link>

          <!-- <router-link
                  to="/masters/payment-categories/list"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">kategori Pembayaran</span>
                    </a>
                  </li>
                </router-link> -->

          <router-link
            to="/masters/departemens/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_department"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Departemen</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/positions/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_position"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Jabatan</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/bank-accounts/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_bank_account"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Rekening Bank</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/masters/announcement-categories/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_announcement_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Kategori Pengumuman</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/masters/uoms"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_uom"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Satuan </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/masters/coa-categories/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_coa_category"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Kategori COA</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/masters/sub-ledgers/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_sub_ledger"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Sub Ledger</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/masters/cash-flows/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="master_cash_flow"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Cash Flow</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-pages'),
      }"
      v-if="system_setting"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-cogs mr-5 menu-icon"></i>
        <span class="menu-text"> Setelan Sistem </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/help"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="system"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Sistem </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/help"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="setting_system"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Atur Sistem </span>
              </a>
            </li>
          </router-link>
          <!-- <router-link
            to="/custom-pages/profile"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link"
                @click="navigate"
              >
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Payment Gateway </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/custom-pages/profile"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link"
                @click="navigate"
              >
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Impor </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/custom-pages/profile"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link"
                @click="navigate"
              >
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Backup Database </span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/user-managements'),
      }"
      v-if="user_management"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="fas fa-cogs mr-5 menu-icon"></i>
        <span class="menu-text"> Manajemen User </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/user-managements/user-access"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="user_access"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Hak Akses Pengguna </span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/user-managements/position-access"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="position_access"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Hak Akses Jabatan </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      to="/profile"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="profile"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-user mr-5 menu-icon"></i>
          <span class="menu-text">Profile</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/help"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-question-circle mr-5 menu-icon"></i>
          <span class="menu-text">Bantuan</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import ApiService from "@/core/services/api.service.js";

export default {
  name: "KTMenu",
  data() {
    return {
      dashboard: false,
      polyclinic: false,
      my_appointment: false,
      eksekutif_dashboard: false,
      // doctor
      titleDoctorPatient: false,
      doctor: false,
      doctor_list: false,
      doctor_treatment: false,
      // schedule
      schedule: false,
      schedule_list: false,
      schedule_holiday: false,
      // patient
      patient: false,
      patient_list: false,
      patient_case: false,
      patient_case_print: false,
      patient_document: false,
      // appointment
      appointment: false,
      appointment_list: false,
      appointment_add: false,
      appointment_today: false,
      appointment_upcoming: false,
      appointment_calendar: false,
      // payment
      titlePaymentHumanResource: false,
      payment: false,
      payment_list: false,
      payment_add: false,
      payment_category: false,
      expense_list: false,
      expense_add: false,
      expense_category: false,
      coa: false,
      payment_po: false,
      // human resource
      human_resource: false,
      human_resource_employee: false,
      payroll_list: false,
      remuneration: false,
      // operational
      titleOperational: false,
      // prescription
      prescription: false,
      // medicine
      medicine: false,
      medicine_list: false,
      medicine_add: false,
      medicine_category: false,
      medicine_stock: false,
      medicine_vendor: false,
      stock_opname: false,
      // lab
      lab: false,
      lab_procedure: false,
      lab_report_list: false,
      lab_report_add: false,
      lab_template: false,
      // medical action
      medical_action: false,
      medical_action_list: false,
      medical_action_add: false,
      // bed
      bed: false,
      bed_list: false,
      bed_add: false,
      bed_category: false,
      bed_allotment: false,
      bed_add_allotment: false,
      // donor
      blood_bank: false,
      blood_bank_list: false,
      donor_list: false,
      donor_add: false,
      donor_recipient: false,
      // pharmacy
      titlePharmacy: false,
      pharmacy: false,
      pharmacy_dashboard: false,
      pharmacy_sales: false,
      pharmacy_cashier: false,
      pharmacy_expense_list: false,
      pharmacy_expense_add: false,
      pharmacy_expense_category_list: false,
      pharmacy_report: false,
      pharmacy_po: false,
      // announcement
      announcement: false,
      // report
      titleReportAnalysis: false,
      report: false,
      report_payment_report: false,
      report_doctor_commision: false,
      report_expense_report: false,
      report_pharmacy_report: false,
      report_pharmacy_expense_report: false,
      journal_report_list: false,
      payroll_report_list: false,
      stock_opname_report_list: false,
      // data analysisi
      data_analysis: false,
      // setting
      titleWebsite: false,
      setting: false,
      // website
      cms: false,
      website_setting: false,
      website_service: false,
      website_slide: false,
      website_doctor: false,
      website_post: false,
      website_post_category: false,
      // master
      titleEtc: false,
      master: false,
      master_appointment_status: false,
      master_uom: false,
      master_action_type: false,
      classification: false,
      master_lab_category: false,
      master_building: false,
      master_room: false,
      master_payment_method: false,
      master_payment_category_type: false,
      master_department: false,
      master_position: false,
      master_remuneration: false,
      master_announcement_category: false,
      master_announcement: false,
      master_coa_category: false,
      master_sub_ledger: false,
      master_cash_flow: false,
      master_bank_account: false,
      master_warehouse: false,
      // setelan sistem
      system_setting: false,
      system: false,
      setting_system: false,
      // user management
      user_management: false,
      user_access: false,
      position_access: false,
      // profile
      profile: false,
      help: false,
      // online shop
      online_shop: false,
      system_online_shop: false,
      // shortcut obat dan alkes
      shortcut_obat_alkes: false,
      // rekapitulasi tindakan perbulan
      recap_action_report: false,
      recap_action_report_list: false,
      recap_action_report_manipulate: false,
      // Satu sehat
      satu_sehat_menu: false,
      satu_sehat_api: false,
      satu_sehat_posting: false,
      satu_sehat_dashboard: false,
    };
  },
  methods: {
    hasActiveChildren(match) {
      if (match.constructor === Array) {
        for (let i = 0; i < match.length; i++) {
          if (this.$route["path"].indexOf(match[i]) !== -1) {
            return true;
          }
        }
      } else {
        return this.$route["path"].indexOf(match) !== -1;
      }
    },

    getAccessRight() {
      ApiService.get("users/me").then((data) => {
        ApiService.get("users/" + data.data.data.user_id).then((response) => {
          console.log("user access", response);
          if (data.data.data.position_id) {
            ApiService.get("positions/" + data.data.data.position_id).then(
              (result) => {
                console.log("position", result);

                let access_right = response.data.meta.access_right_display;
                let position_right = result.data.meta.access_right_display;

                let merge = [...access_right, ...position_right];
                console.log("merge", merge);

                window.localStorage.setItem(
                  "access_right_display",
                  JSON.stringify(merge)
                );

                this.setActiveMenu(result.data.meta.access_right_display);
              }
            );
          } else if (!data.data.data.position_id) {
            // let user = data.data.data
            // access_right = response.data.meta.access_right
            // user.access_right = response.data.meta.access_right

            // console.log("doctor access", response)

            this.setActiveMenu(response.data.meta.access_right_display);
            window.localStorage.setItem(
              "access_right_display",
              JSON.stringify(response.data.meta.access_right_display)
            );
          }
        });
      });
    },

    async setActiveMenu(accessParams = "") {
      let access_right;
      if (accessParams == "") {
        let access_right_user = window.localStorage.getItem(
          "access_right_display"
        );
        access_right = JSON.parse(access_right_user);
        console.log("accs", access_right);
      } else if (accessParams != "") {
        access_right = accessParams;
      }

      let user_type = window.localStorage.getItem("user");
      let user_type_arr = JSON.parse(user_type);
      let user_type_id = user_type_arr.user_type_id;

      if (user_type_id == 1) {
        this.my_appointment = true;
      }

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001") {
          this.dashboard = true;
        }
        if (access_right[a] == "1002") {
          this.polyclinic = true;
        }
        if (access_right[a] == "1003") {
          this.my_appointment = true;
        }
        if (access_right[a] == "1004") {
          this.eksekutif_dashboard = true;
        }
        if (access_right[a] == "2001") {
          this.titleDoctorPatient = true;
          this.doctor = true;
          this.doctor_list = true;
        }
        if (access_right[a] == "2003") {
          this.titleDoctorPatient = true;
          this.doctor = true;
          this.doctor_treatment = true;
        }
        if (access_right[a] == "3001") {
          this.patient = true;
          this.patient_list = true;
        }
        if (access_right[a] == "3005") {
          this.titleDoctorPatient = true;
          this.patient = true;
          this.patient_case = true;
          this.patient_case_print = true;
        }
        if (access_right[a] == "3007") {
          this.titleDoctorPatient = true;
          this.patient = true;
          this.patient_document = true;
        }
        if (access_right[a] == "4001") {
          this.titleDoctorPatient = true;
          this.schedule = true;
          this.schedule_list = true;
        }
        if (access_right[a] == "4001") {
          this.titleDoctorPatient = true;
          this.schedule = true;
          this.schedule_holiday = true;
        }
        if (access_right[a] == "5001") {
          this.titleDoctorPatient = true;
          this.appointment = true;
          this.appointment_list = true;
          this.appointment_today = true;
          this.appointment_upcoming = true;
          this.appointment_calendar = true;
        }
        if (access_right[a] == "5002") {
          this.titleDoctorPatient = true;
          this.appointment = true;
          this.appointment_add = true;
        }
        if (access_right[a] == "6001") {
          this.titlePaymentHumanResource = true;
          this.payment = true;
          this.payment_list = true;
        }
        if (access_right[a] == "6002") {
          this.titlePaymentHumanResource = true;
          this.payment = true;
          this.payment_add = true;
        }
        if (access_right[a] == "6003") {
          this.payment = true;
          this.payment_category = true;
        }
        if (access_right[a] == "6004") {
          this.titlePaymentHumanResource = true;
          this.payment = true;
          this.expense_list = true;
        }
        if (access_right[a] == "6005") {
          this.titlePaymentHumanResource = true;
          this.payment = true;
          this.expense_add = true;
        }
        if (access_right[a] == "6006") {
          this.titlePaymentHumanResource = true;
          this.payment = true;
          this.expense_category = true;
        }
        if (access_right[a] == "6008") {
          this.titlePaymentHumanResource = true;
          this.payment = true;
          this.coa = true;
        }
        if (access_right[a] == "6001") {
          this.titlePaymentHumanResource = true;
          this.payment = true;
          this.payment_list = true;
        }
        if (access_right[a] == "6021") {
          this.titlePaymentHumanResource = true;
          this.payment = true;
          this.payment_po = true;
        }
        if (access_right[a] == "7001") {
          this.titlePaymentHumanResource = true;
          this.human_resource = true;
          this.human_resource_employee = true;
        }
        if (access_right[a] == "7003") {
          this.titlePaymentHumanResource = true;
          this.human_resource = true;
          this.payroll_list = true;
        }
        if (access_right[a] == "7005") {
          this.titlePaymentHumanResource = true;
          this.human_resource = true;
          this.remuneration = true;
        }
        if (access_right[a] == "8001") {
          this.titleDoctorPatient = true;
          this.prescription = true;
        }
        if (access_right[a] == "9001") {
          this.titlePharmacy = true;
          this.medicine = true;
          this.medicine_list = true;
        }
        if (access_right[a] == "9002") {
          this.titlePharmacy = true;
          this.medicine = true;
          this.medicine_add = true;
        }
        if (access_right[a] == "9003") {
          this.titlePharmacy = true;
          this.medicine = true;
          this.medicine_category = true;
        }
        if (access_right[a] == "9004") {
          this.titlePharmacy = true;
          this.medicine = true;
          this.medicine_stock = true;
        }
        if (access_right[a] == "9006") {
          this.titlePharmacy = true;
          this.medicine = true;
          this.medicine_vendor = true;
        }
        if (access_right[a] == "9008") {
          this.titlePharmacy = true;
          this.medicine = true;
          this.stock_opname = true;
        }
        if (access_right[a] == "9009") {
          this.titlePharmacy = true;
          this.medicine = true;
          this.stock_opname = true;
        }
        if (access_right[a] == "1101") {
          this.titleOperational = true;
          this.lab = true;
          this.lab_procedure = true;
        }
        if (access_right[a] == "1103") {
          this.titleOperational = true;
          this.lab = true;
          this.lab_report_list = true;
        }
        if (access_right[a] == "1104") {
          this.titleOperational = true;
          this.lab = true;
          this.lab_report_add = true;
        }
        if (access_right[a] == "1105") {
          this.titleOperational = true;
          this.lab = true;
          this.lab_template = true;
        }
        if (access_right[a] == "1201") {
          this.titleOperational = true;
          this.medical_action = true;
          this.medical_action_list = true;
        }
        if (access_right[a] == "1202") {
          this.titleOperational = true;
          this.medical_action = true;
          this.medical_action_add = true;
        }
        if (access_right[a] == "1203") {
          this.titleOperational = true;
          this.medical_action = true;
          this.master_action_type = true;
        }
        if (access_right[a] == "1301") {
          this.titleOperational = true;
          this.bed = true;
          this.bed_list = true;
        }
        if (access_right[a] == "1302") {
          this.titleOperational = true;
          this.bed = true;
          this.bed_add = true;
        }
        if (access_right[a] == "1304") {
          this.titleOperational = true;
          this.bed = true;
          this.bed_category = true;
        }
        if (access_right[a] == "1305") {
          this.titleOperational = true;
          this.bed = true;
          this.bed_allotment = true;
          this.bed_add_allotment = true;
        }
        if (access_right[a] == "1303") {
          this.titleOperational = true;
          this.bed = true;
          this.bed_allotment = true;
        }
        if (access_right[a] == "1305") {
          this.titleOperational = true;
          this.bed = true;
          this.bed_add_allotment = true;
        }
        if (access_right[a] == "2201") {
          this.titleOperational = true;
          this.blood_bank = true;
          this.donor_list = true;
        }
        if (access_right[a] == "2202") {
          this.titleOperational = true;
          this.blood_bank = true;
          this.donor_add = true;
        }
        if (access_right[a] == "2401") {
          this.titleOperational = true;
          this.blood_bank = true;
          this.donor_recipient = true;
        }
        if (access_right[a] == "2501") {
          this.titleOperational = true;
          this.blood_bank = true;
          this.blood_bank_list = true;
        }
        if (access_right[a] == "1401") {
          this.titlePharmacy = true;
          this.pharmacy = true;
          this.pharmacy_sales = true;
        }
        if (access_right[a] == "1402") {
          this.titlePharmacy = true;
          this.pharmacy = true;
          this.pharmacy_cashier = true;
        }
        if (access_right[a] == "1403") {
          this.titlePharmacy = true;
          this.pharmacy = true;
          this.pharmacy_expense_list = true;
        }
        if (access_right[a] == "1404") {
          this.titlePharmacy = true;
          this.pharmacy = true;
          this.pharmacy_expense_add = true;
        }
        if (access_right[a] == "1405") {
          this.titlePharmacy = true;
          this.pharmacy = true;
          this.pharmacy_expense_category_list = true;
        }
        if (access_right[a] == "1406") {
          this.titlePharmacy = true;
          this.pharmacy = true;
          this.pharmacy_po = true;
        }
        if (access_right[a] == "2301") {
          this.titlePharmacy = true;
          this.announcement = true;
        }
        if (access_right[a] == "1501") {
          this.titleReportAnalysis = true;
          this.report = true;
          this.report_payment_report = true;
        }
        if (access_right[a] == "1502") {
          this.titleReportAnalysis = true;
          this.report = true;
          this.report_doctor_commision = true;
        }
        if (access_right[a] == "1503") {
          this.titleReportAnalysis = true;
          this.report = true;
          this.report_expense_report = true;
        }
        if (access_right[a] == "1504") {
          this.titleReportAnalysis = true;
          this.report = true;
          this.report_pharmacy_report = true;
        }
        if (access_right[a] == "1505") {
          this.titleReportAnalysis = true;
          this.report = true;
          this.report_pharmacy_expense_report = true;
        }
        if (access_right[a] == "1506") {
          this.titleReportAnalysis = true;
          this.report = true;
          this.journal_report_list = true;
        }
        if (access_right[a] == "1507") {
          this.titleReportAnalysis = true;
          this.report = true;
          this.payroll_report_list = true;
        }
        if (access_right[a] == "1508") {
          this.titleReportAnalysis = true;
          this.report = true;
          this.stock_opname_report_list = true;
        }
        if (access_right[a] == "1601") {
          this.system_setting = true;
          this.system = true;
        }
        if (access_right[a] == "1602") {
          this.system_setting = true;
          this.setting_system = true;
        }
        if (access_right[a] == "2601") {
          this.online_shop = true;
          this.system_online_shop = true;
        }

        if (access_right[a] == "1701") {
          this.titleWebsite = true;
          this.setting = true;
          this.cms = true;
          this.website_setting = true;
          this.website_service = true;
          this.website_slide = true;
          this.website_doctor = true;
          this.website_post = true;
          this.website_post_category = true;
        }
        if (access_right[a] == "1801") {
          this.titleEtc = true;
          this.master = true;
          this.master_lab_category = true;
          this.master_building = true;
          this.master_room = true;
          this.master_payment_method = true;
          this.master_payment_category_type = true;
          this.master_department = true;
          this.master_position = true;
          this.master_remuneration = true;
          this.master_announcement_category = true;
          this.master_announcement = true;
          this.master_coa_category = true;
          this.master_sub_ledger = true;
          this.master_cash_flow = true;
          this.master_appointment_status = true;
          this.master_uom = true;
          this.master_bank_account = true;
          this.master_warehouse = true;
        }
        if (access_right[a] == "1802") {
          this.titleEtc = true;
          this.master = true;
          this.master_lab_category = true;
          this.master_building = true;
          this.master_room = true;
          this.master_payment_method = true;
          this.master_payment_category_type = true;
          this.master_department = true;
          this.master_position = true;
          this.master_remuneration = true;
          this.master_announcement_category = true;
          this.master_announcement = true;
          this.master_coa_category = true;
          this.master_sub_ledger = true;
          this.master_cash_flow = true;
          this.master_appointment_status = true;
          this.master_uom = true;
          this.master_action_type = true;
          this.master_bank_account = true;
          this.master_warehouse = true;
        }
        if (access_right[a] == "1803" || access_right[a] == "1803") {
          this.titleEtc = true;
          this.classification = true;
        }
        if (access_right[a] == "1901") {
          this.titleEtc = true;
          this.user_management = true;
          this.user_access = true;
          this.position_access = true;
        }
        if (access_right[a] == "2100") {
          this.data_analysis = true;
        }
        if (access_right[a] == "2701") {
          this.shortcut_obat_alkes = true;
        }
        if (access_right[a] == "2800") {
          this.recap_action_report = true;
        }
        if (access_right[a] == "2801") {
          this.recap_action_report_list = true;
        }
        if (access_right[a] == "2802") {
          this.recap_action_report_manipulate = true;
        }
        if (access_right[a] == "2901") {
          this.satu_sehat_dashboard = true;
          this.satu_sehat_menu = true;
        }
        if (access_right[a] == "2902") {
          this.satu_sehat_posting = true;
          this.satu_sehat_menu = true;
        }
        if (access_right[a] == "2903") {
          this.satu_sehat_api = true;
          this.satu_sehat_menu = true;
        }
      }
    },
  },
  mounted() {
    // this.getAccessRight()
    // Add Website Post
    this.setActiveMenu();
    this.$root.$on("setMenuActive", () => {
      console.log("refresh menu");
      this.getAccessRight();
    });
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>

<style scoped>
.menu-title-color {
  color: #000 !important;
}

.sub-menu-icon-color {
  color: #993366 !important;
}

.menu-bullet .menu-bullet-dot {
  background-color: #993366 !important;
}
</style>